import React from 'react';
import { AlertNotification, Button, colors, LabeledIconInput, PercentIcon, Popup, Row, XIcon } from '@commonsku/styles';

type SetBulkMarginPopupProps = {
    items: Item[];
    onSetMarginClick: () => void;
    onClose: () => void;
    orderType: string;
    orderCurrencyId: string;
};

interface Item {
    copied_from: string;
    currency_id: string;
};

export default function SetBulkMarginPopup({
    items,
    onSetMarginClick,
    onClose,
    orderType,
    orderCurrencyId,
}: SetBulkMarginPopupProps) {
    const psProductsCount = items.filter((i) => i.copied_from === 'ps-products').length;
    let warning = 'Setting the margin will overwrite any previously entered margins for these items, for all quantity breaks.';

    if (orderType === 'PRESENTATION') {
        warning += '\n\nSetting the margin will also affect run charges.';
    }

    if (psProductsCount > 0) {
        warning += '\n\nDifferent margins will be calculated for different quantities [TBD].';
    }

    const differentCurrencies = items.filter((i) => i.currency_id !== orderCurrencyId);
    const error = differentCurrencies.length > 0 ? `${differentCurrencies.length} of the ${items.length} selected products have a different currency from the order's currency (${orderCurrencyId}). Deselect those products, and try again.` : null;
    const header = error ? `Can't set margin on ${differentCurrencies.length}/${items.length} products` : `Set margin on ${items.length} product${items.length === 1 ? '' : 's'}`;

    return <Popup
            style={{
            padding: 20,
            width: 'calc(100vw - 100px)',
            maxWidth: '50rem',
            height: 'auto',
            maxHeight: 'initial'
        }}
        header={
            <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                <h2 style={{
                    margin: '0.2em',
                    fontFamily: 'skufont-demibold',
                    fontSize: 26,
                    fontWeight: 500,
                    lineHeight: '26px',
                    color: colors.neutrals[90],
                }}>{header}</h2>
                <div>
                    <XIcon cursor={'pointer'} onClick={onClose} mt={2} ml={10} size='large' />
                </div>
            </Row>
        }
    >
        <AlertNotification
            alertType={error ? 'error' : 'warn'}
            style={{
                marginTop: 10,
                whiteSpace: 'pre-wrap',
            }}
        >{error ?? warning}</AlertNotification>

        {!error &&
            <>
                <LabeledIconInput
                    labelOnTop
                    label={null}
                    name='margin'
                    placeholder='Enter new margin here'
                    Icon={<PercentIcon />}
                    style={{ width: 250, marginTop: 20, marginBottom: 20 }}
                />

                <Row style={{float: 'right'}}>
                    <Button
                        onClick={onClose}
                        variant='secondary'
                    >Cancel</Button>

                    <Button
                        ml={15}
                        onClick={onSetMarginClick}
                    >Set margin for {items.length} item{items.length === 1 ? '' : 's'}</Button>
                </Row>
            </>
        }
    </Popup>;
}