import React from 'react';

import loadable from '@loadable/component';

let registry = {
  AddAddressPopup: loadable(() => import('./components/AddAddressPopup')),
  AddContactPopup: loadable(() => import('./components/AddContactPopup')),
  AddItemLocationPopup: loadable(() => import('./components/AddItemLocationPopup')),
  AddNewProductPopup: loadable(() => import('./components/AddNewProductPopup')),
  AddProductToOrderPopup: loadable(() => import('./components/AddProductToOrderPopup')),
  AddressListPopup: loadable(() => import('./components/AddressListPopup')),
  AddShippingQuotePopup: loadable(() => import('./components/AddShippingQuotePopup')),
  AddShopAddressPopup: loadable(() => import('./components/AddShopAddressPopup')),
  AddShopContactPopup: loadable(() => import('./components/AddShopContactPopup')),
  AddSupplierPopup: loadable(() => import('./components/AddSupplierPopup')),
  AddTemplatePopup: loadable(() => import('./components/AddTemplatePopup')),
  AddThirdPartyShippingAccountPopup: loadable(() => import('./components/AddThirdPartyShippingAccountPopup')),
  AddToOrderPopup: loadable(() => import('./components/AddToOrderPopup')),
  AddToQuotePopup: loadable(() => import('./components/AddToQuotePopup')),
  AddYouTubePopup: loadable(() => import('./components/AddYouTubePopup')),
  ArtworkLibrary: loadable(() => import('./components/ArtworkLibrary')),
  AssignToClientPopup: loadable(() => import('./components/AssignToClientPopup')),
  NewFeaturePopup: loadable(() => import('./components/NewFeaturePopup')),
  SelectSkuOrAxisOptionPopup: loadable(() => import('./components/SelectSkuOrAxisOptionPopup')),
  CardConnectPopup: loadable(() => import('./components/CardConnectPopup')),
  CopyItemPopup: loadable(() => import('./components/CopyItemPopup')),
  EditCommissionPopup: loadable(() => import('./components/EditCommissionPopup')),
  ColorSizeSubsetPopup: loadable(() => import('./components/ColorSizeSubsetPopup')),
  SkuAxesSubsetPopup: loadable(() => import('./components/SkuAxesSubsetPopup')),
  CheckOrderStatusPopup: loadable(() => import('./components/CheckOrderStatusPopup')),
  UpgradePresentationPopup: loadable(() => import('./components/UpgradePresentationPopup')),
  UpgradeEstimatePopup: loadable(() => import('./components/UpgradeEstimatePopup')),
  SelectDecorationLocationPopup: loadable(() => import('./components/SelectDecorationLocationPopup')),
  SelectItemImagesPopup: loadable(() => import('./components/SelectItemImagesPopup')),
  ErrorPopup: loadable(() => import('./components/ErrorPopup')),
  ErrorAlertPopup: loadable(() => import('./components/ErrorAlertPopup')),
  UpdateNetCostPopup: loadable(() => import('./components/UpdateNetCostPopup')),
  EditServicePopup: loadable(() => import('./components/EditServicePopup')),
  ContactPopup: loadable(() => import('./components/ContactPopup')),
  EditPSEndpointPopup: loadable(() => import('./components/EditPSEndpointPopup')),
  PasswordPopup: loadable(() => import('./components/PasswordPopup')),
  SupportPopup: loadable(() => import('./components/SupportPopup')),
  EditSalesTargetPopup: loadable(() => import('./components/EditSalesTargetPopup')),
  NoticePopup: loadable(() => import('./components/NoticePopup')),
  SelectDecorationProductPopup: loadable(() => import('./components/SelectDecorationProductPopup')),
  RegeneratePurchaseOrdersPopup: loadable(() => import('./components/RegeneratePurchaseOrdersPopup')),
  EditArtworkPopup: loadable(() => import('./components/EditArtworkPopup')),
  EditTenantPSCredentialPopup: loadable(() => import('./components/EditTenantPSCredentialPopup')),
  SelectTaxPopup: loadable(() => import('./components/SelectTaxPopup')),
  EditCompanyShippingAndBillingPopup: loadable(() => import('./components/EditCompanyShippingAndBillingPopup')),
  EditMessagePopup: loadable(() => import('./components/EditMessagePopup')),
  TourMilestonePopup: loadable(() => import('./components/TourMilestonePopup')),
  ClosedShopPopup: loadable(() => import('./components/ClosedShopPopup')),
  SelectSkuOptionsPopup: loadable(() => import('./components/SelectSkuOptionsPopup')),
  SelectPSSkuOptionsByColorSizePopup: loadable(() => import('./components/SelectPSSkuOptionsByColorSizePopup')),
  MetadataListPopup: loadable(() => import('./components/MetadataListPopup')),
  SelectImagePopup: loadable(() => import('./components/SelectImagePopup')),
  SelectFobPopup: loadable(() => import('./components/SelectFobPopup')),
  PSTestPopup: loadable(() => import('./components/PSTestPopup')),
  ValidateConfirmationPopup: loadable(() => import('./components/ValidateConfirmationPopup')),
  AddNewPSLocationPopup: loadable(() => import('./components/AddNewPSLocationPopup')),
  ConfirmationForDeletePopup: loadable(() => import('./components/ConfirmationForDeletePopup')),
  SelectCollectionSupplierPopup: loadable(() => import('./components/SelectCollectionSupplierPopup')),
  EditBillPopup: loadable(() => import('./components/EditBillPopup')),
  CartSummaryPopup: loadable(() => import('./components/CartSummaryPopup')),
  SpinnerPopup: loadable(() => import('./components/SpinnerPopup')),
  CreateProjectAndOrderPopup: loadable(() => import('./components/CreateProjectAndOrderPopup')),
  CreateClientPopup: loadable(() => import('./components/CreateClientPopup')),
  ClientImportPopup: loadable(() => import('./components/company-search/popups/ClientImportPopup')),
  ClientImportPreviewPopup: loadable(() => import('./components/company-search/popups/ClientImportPreviewPopup')),
  SelectShopArtworkPopup: loadable(() => import('./components/SelectShopArtworkPopup')),
  SelectShopArtworkPopupV2: loadable(() => import('./components/shop/popups/SelectShopArtworkPopup')),
  SelectCollectionImagesReminderPopup: loadable(() => import('./components/SelectCollectionImagesReminderPopup')),
  CreditHoldPopup: loadable(() => import('./components/CreditHoldPopup')),
  CommentPopup: loadable(() => import('./components/CommentPopup')),
  IntroPopup: loadable(() => import('./components/IntroPopup')),
  SelectShopHeaderPopup: loadable(() => import('./components/SelectShopHeaderPopup')),
  CopyToProjectPopup: loadable(() => import('./components/CopyToProjectPopup')),
  InviteColleaguesPopup: loadable(() => import('./components/InviteColleaguesPopup')),
  SelectBreakdownSkuPopup: loadable((props) => import('./components/SelectBreakdownSkuPopup')),
  SelectBreakdownSkuByColorPopup: loadable((props) => import('./components/SelectBreakdownSkuByColorPopup')),
  EmailMessagePopup: loadable(() => import('./components/EmailMessagePopup')),
  CreateShopPopup: loadable(() => import('./components/CreateShopPopup')),
  CheckInventoryLevelPopup: loadable(() => import('./components/CheckInventoryLevelPopup')),
  EditHeaderPopup: loadable(() => import('./components/EditHeaderPopup')),
  SelectCollectionItemPopup: loadable(() => import('./components/SelectCollectionItemPopup')),
  NotePopup: loadable(() => import('./components/NotePopup')),
  PartialPopup: loadable(() => import('./components/PartialPopup')),
  ClientDisplayOptionsPopup: loadable(() => import('./components/ClientDisplayOptionsPopup')),
  EditArtworkPOPopup: loadable(() => import('./components/EditArtworkPOPopup')),
  JoinOrdersPopup: loadable(() => import('./components/JoinOrdersPopup')),
  EditProjectPopup: loadable(() => import('./components/EditProjectPopup')),
  SelectFeaturedShopItemPopup: loadable(() => import('./components/SelectFeaturedShopItemPopup')),
  UploadProductImagePopup: loadable(() => import('./components/UploadProductImagePopup')),
  CopyOrderPopup: loadable(() => import('./components/CopyOrderPopup')),
  SelectVariantPopup: loadable(() => import('./components/SelectVariantPopup')),
  ContactListPopup: loadable(() => import('./components/ContactListPopup')),
  SubmitPOPopup: loadable(() => import('./components/SubmitPOPopup')),
  ApplyMatrixToItemPopup: loadable(() => import('./components/ApplyMatrixToItemPopup')),
  UserPopupPreview: loadable(() => import('./components/marketing-settings/UserPopupSettings')),
  UploadFilesPopup: loadable(() => import('./components/UploadFilesPopup')),
  DisplayArtworkPopup: loadable(() => import('./components/DisplayArtworkPopup')),
  SelectFilePopup: loadable(() => import('./components/SelectFilePopup')),
  MPPreferencesPopup: loadable(() => import('./components/MPPreferencesPopup')),
  SelectReferenceProductPopup: loadable(() => import('./components/SelectReferenceProductPopup.js')),
  SelectStorefrontImage: loadable(() => import('./components/shop/image/SelectStorefrontImage.js')),
  SupplierBenefitsPopup: loadable(() => import('./components/SupplierBenefitsPopup.js')),
  EditInventoryBreakdownQuantityPopup: loadable(() => import('./components/EditInventoryBreakdownQuantityPopup.js')),
  ChangeCurrencyPopup: loadable(() => import('./components/ChangeCurrencyPopup.js')),
  AvalaraShopIssuesPopup: loadable(() => import('./components/AvalaraShopIssuesPopup')),
  CheckPSInventoryLevelPopup: loadable(() => import('./components/CheckPSInventoryLevelPopup')),
  ConfirmSwitchPsDecoratorPopup: loadable(() => import('./components/decorate/ConfirmSwitchPsDecoratorPopup')),
  NotifyShippingChangesPopup: loadable(() => import('./components/production-report/NotifyShippingChangesPopup')),
  LoadReportPopup: loadable(() => import('./components/report/action-menu/LoadReportPopup')),
  DeleteReportPopup: loadable(() => import('./components/report/action-menu/DeleteReportPopup')),
  SaveReportPopup: loadable(() => import('./components/report/action-menu/SaveReportPopup')),
  RenameTemplatePopup: loadable(() => import('./components/shop/popups/RenameTemplatePopup')),
  DeleteTemplatePopup: loadable(() => import('./components/shop/popups/DeleteTemplatePopup')),
  PublishTemplatePopup: loadable(() => import('./components/shop/popups/PublishTemplatePopup')),
  ShopInitializationTemplatePopup: loadable(() => import('./components/shop/popups/ShopInitializationTemplatePopup/ShopInitializationTemplatePopup'))
};

export const BASE_ZINDEX = 1006;

const createPopup = (popup, index, props) => {
  if (!popup || !(popup.component in registry)) {
    return;
  }

  const Popup = registry[popup.component];
  const key = props?.key || `createPopup-${popup.component}-${index}`;
  return <Popup {...popup} index={index} {...props} key={key} />;
};

export default createPopup;
