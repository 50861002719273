import { find, bindAll, isEqual } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ArrowIcon, Button, Dropdown } from '@commonsku/styles';

import config from '../config';
import LabelledInput from './LabelledInput';
import LabelledSelect from './LabelledSelect';
import LabelledAddress from './LabelledAddress';
import LabelledContact from './LabelledContact';
import LabelledTextArea from './LabelledTextArea';
import LabelledRadio from './LabelledRadio';
import LabelledCheckbox from './LabelledCheckbox';
import LabelledControl from './LabelledControl';
import ItemList from './ItemList';
import OrderSummary from './OrderSummary';
import ProjectMarginSummary from './ProjectMarginSummary';
import OrderMarginSummary from './OrderMarginSummary';
import TaxSelect from './TaxSelect';
import { isAvalaraOrder } from '../helpers/order';
import { SanmarNotificationBar } from './SanmarModal';

import { getOrderByNumber, getAddress, getContact, getCompanyData } from '../selectors';
import { getStatusOptions, getServiceOptions, getBillingAddressOptions, getShippingAddressOptions, getContactOptions, getCurrencyOptions, getTermsOptions, getTaxOptions } from '../selectors/dropdowns';

import { updateOrder, updateOrderContact, updateOrderAddress, createLoadOrder, createLoadPartialList } from '../actions/order';
import { createRemovePartials } from '../actions/shop';
import { createAddDepositInvoice, createUpdateDepositInvoice, createDeleteDepositInvoice } from '../actions/deposit_invoice';
import { createUpdateDisplayItemSummaryView, createUpdateDisplayItemSummaryShowActive, createUpdateDisplayItemSummaryTab, createUpdateCollapseOrderInfo } from '../actions/display';
import { getOrderTypeLabel } from '../helpers/order';

import {
  createAddAddressPopup,
  createAddContactPopup,
  createPartialPopup,
  createValidateConfirmationPopup,
  createErrorPopup,
  createNoticePopup,
  closePopup
} from '../actions/popup';

import { oauth, openWindowWithPost } from '../utils';
import PartialList from './PartialList';
import AvalaraCategorySelect from './avalara/AvalaraCategorySelect';
import { ExportViewerPortal, ExportViewer } from './export-report/ExportViewer';
import ReactTooltip from './helpers/ReactTooltip';
import { Span } from './helpers/Typography';
import { ExportViewerPopup } from './export-report/ExportViewerPopup';

const DesignBetaOption = (
  <Span data-for="design-beta-tooltip" data-tip="" style={{ paddingLeft: 2, verticalAlign: 'bottom' }}>
    <Span bold>Design Beta</Span>
    <ArrowIcon
      color='var(--color-pink-60)'
      direction='left'
      height={24}
      width={24}
      style={{ paddingLeft: 8, paddingRight: 4, verticalAlign: 'middle', }}
    />
    <Span bold color='var(--color-pink-60)'>New!</Span>
  </Span>
);

const PRESENTATION_VIEW_TYPES = [
  {key: 'TILE', value: 'Tiles'},
  {key: 'LIST', value: 'List'},
  {key: 'GALLERY', value: 'Gallery'},
  {key: 'SMART', value: 'Smart Gallery'},
  {key: 'SHOP_DESIGN', value: 'Design Beta', children: DesignBetaOption},
];

const INVOICE_VIEW_TYPES = [
  {key: 'DETAIL', value: 'Detail'},
  {key: 'SUMMARY', value: 'Summary'}
];

// const ORDER_DATE_FIELD = [
//   'date_orderdate', 'date_expiry', 'date_inhandsdate'
// ];

class ProjectOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      exportTaskIds: [],
      order: Object.assign({ loaded: false }, props.order),
      selected_partials: [],
      in_aggregating: false,
      partials_loaded: false,
      exportingPdf: false,
    };

     bindAll(this, ['onChangeOrder', 'hasCapability', 'handleToggleCollapse', 'exportPartialsCSV', 'exportPartialsPDF', 'handleLoadPartials', 'handleClickPartial', 'handleSelectAllPartials', 'handleRemovePartials']);

  }

  componentDidMount() {
    if (!this.props.order.loaded) {
      this.props.loadOrder(this.props.order.order_id);
    }

    if (this.props.order.is_aggregate == 1 && this.props.order.in_aggregating == 1) {
      this.props.onCreateNoticePopup();
      this.checkShopAggregateStatus();
    }
  }

  componentDidUpdate(prevProps) {
    const prevOrder = prevProps.order;
    const { order, loadOrder } = this.props;
    if (!isEqual(order, prevOrder)) {
      this.setState({
        order: Object.assign({ loaded: false }, order)
      });
      if (order.order_id !== prevOrder.order_id || !order.loaded) {
        loadOrder(order.order_id);
      }
    }
  }

  checkShopAggregateStatus() {
    const { order, project } = this.props;

    this.interval = setInterval(function () {
        return oauth('GET', `shop/${order.shop_id}`, {}).then(({ json }) => {
          if(json.shop.in_aggregating == 0) {
            window.location = `/project/${project.job_number}/sales-order/${order.form_number}`;
          }
        });
    }, 15000);
  }

  onChangeOrder(field) {
    return value => {
      this.setState({
        order: Object.assign({}, this.state.order, {[field]: value})
      });
    };
  }

  handleToggleCollapse(e) {
    this.props.onUpdateCollapsed(this.state.order.order_id, !this.props.collapsed);
  }

  renderBasicInfo() {
    const { order } = this.state;
    const { statuses, onUpdateOrder } = this.props;
    const locked = 1 == order.locked;

    return (
      <div>
        <div className="small-12 large-3 large-push-9 columns">
          <a className="hollow button trigger-button" style={{ float: 'right' }} onClick={this.handleToggleCollapse}>+ Expand Info</a>
        </div>
        <LabelledSelect className="small-12 medium-6 large-3 large-pull-3 columns" label="Status" labelStyle={{ lineHeight: 1.55 }} value={order.status_id} options={statuses} onChange={onUpdateOrder(order.order_id, 'status_id', order.status_id)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns end" label={`${getOrderTypeLabel(order)} Date`} type="date" value={order.date_orderdate} onChange={this.onChangeOrder('date_orderdate')} onBlur={onUpdateOrder(order.order_id, 'date_orderdate', order.date_orderdate)} disabled={locked} />
      </div>
    );
  }

  renderTaxInput() {
    const { project, onUpdateOrder } = this.props;
    const { order } = this.state;
    return [
      <LabelledControl key="default_tax" className="small-12 medium-6 large-3 columns" label="Default Tax">
        <TaxSelect
          value={order.tax_id} zip2tax={1 == project.zip2tax} disabled={order.locked == 1}
          onChange={onUpdateOrder(order.order_id, 'tax_id', order.tax_id)} avalara={true}
        />
      </LabelledControl>,
      isAvalaraOrder(order) && <LabelledControl
        key="avalara_tax" className="small-12 medium-6 large-3 columns" label="Default Avalara Category"
      >
        <AvalaraCategorySelect
          value={order.default_avalara_category_id}
          onChange={onUpdateOrder(
            order.order_id, 'default_avalara_category_id',
            order.default_avalara_category_id
          )}
        />
      </LabelledControl>,
    ];
  }

  renderPresentationInfo() {
    const { order } = this.state;
    const {
      identity,
      project,
      billing_contact,
      contacts,
      statuses,
      currencies,
      onUpdateOrder,
      onUpdateBillingContact,
      onAddContact,
    } = this.props;
    const locked = 1 == order.locked;
    const intro = order && order.introduction ? (order.introduction).replace(/\<br \/?>/g, "") : '';
    const newDesignEnabled = order.view_type === 'SHOP_DESIGN'
      || identity?.user_flags?.includes('SHOP_DESIGN_ENABLED');
    const presentationViewTypes = newDesignEnabled
      ? PRESENTATION_VIEW_TYPES
      : PRESENTATION_VIEW_TYPES.filter(v => v.key !== 'SHOP_DESIGN');

    return (
      <Fragment>
        <ReactTooltip id="design-beta-tooltip" type="success">
          <div>
            <Span bold style={{ color: 'var(--color-green-90)' }}>
              Unlock the "Design" tab for new presentation themes and customizations!
            </Span>
          </div>
        </ReactTooltip>

        <div className="small-12 large-3 large-push-9 columns">
          <a className="hollow button trigger-button" style={{ float: 'right' }} onClick={this.handleToggleCollapse}>- Collapse Info</a>
        </div>
        <LabelledSelect className="small-12 medium-6 large-3 large-pull-3 columns" labelStyle={{ lineHeight: 1.55 }} label="Status" value={order.status_id} options={statuses} onChange={onUpdateOrder(order.order_id, 'status_id', order.status_id)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns" label="Presentation Date" type="date" value={order.date_orderdate} onChange={this.onChangeOrder('date_orderdate')} onBlur={onUpdateOrder(order.order_id, 'date_orderdate', order.date_orderdate)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns" label="In Hands Date" type="date" value={order.date_inhandsdate} onChange={this.onChangeOrder('date_inhandsdate')} onBlur={onUpdateOrder(order.order_id, 'date_inhandsdate', order.date_inhandsdate)} disabled={locked} />
        <LabelledTextArea className="small-12 columns" label="Introduction" value={intro} onChange={this.onChangeOrder('introduction')} onBlur={onUpdateOrder(order.order_id, 'introduction', order.introduction)} disabled={locked} style={{ minWidth: '100%' }} />
        <LabelledContact
          className="small-12 medium-6 large-3 columns"
          label="Client Contact"
          contact={billing_contact}
          onChangeContact={onUpdateBillingContact(order.order_id, order.billing_contact_id, order.billing_address_id)}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'billing_contact_id', order.billing_contact_id)}
        />
        <LabelledRadio className="small-12 medium-6 large-4 columns" label="Presentation Style" value={order.view_type} options={presentationViewTypes} onChange={onUpdateOrder(order.order_id, 'view_type', order.view_type)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Expiry Date" type="date" value={order.date_expiry} onChange={this.onChangeOrder('date_expiry')} onBlur={onUpdateOrder(order.order_id, 'date_expiry', order.date_expiry)} disabled={locked}/>
        <LabelledSelect className="small-12 medium-6 large-2 columns" label="Currency" value={order.currency_id} options={currencies} onChange={onUpdateOrder(order.order_id, 'currency_id', order.currency_id)} disabled={true} />
        <LabelledCheckbox className="small-12 medium-6 large-3 columns end" label="Hide Pricing" value={order.hide_pricing} onChange={onUpdateOrder(order.order_id, 'hide_pricing', order.hide_pricing)} />
      </Fragment>
    );
  }

  renderEstimateInfo() {
    const { order } = this.state;
    const {
      project,
      billing_address,
      billing_contact,
      shipping_address,
      shipping_contact,
      shippingAddresses,
      billingAddresses,
      contacts,
      statuses,
      currencies,
      terms,
      onUpdateOrder,
      onUpdateBillingContact,
      onUpdateShippingContact,
      onUpdateShippingAddress,
      onAddAddress,
      onAddContact
    } = this.props;
    const locked = 1 == order.locked;
    const intro = order && order.introduction ? (order.introduction).replace(/\<br \/?>/g, "") : '';
    return (
      <div>
        <div className="small-12 large-3 large-push-9 columns">
          <a className="hollow button trigger-button" style={{ float: 'right' }} onClick={this.handleToggleCollapse}>- Collapse Info</a>
        </div>
        <LabelledSelect className="small-12 medium-6 large-3 large-pull-3 columns" label="Status" labelStyle={{ lineHeight: 1.55 }} value={order.status_id} options={statuses} onChange={onUpdateOrder(order.order_id, 'status_id', order.status_id)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns" label="Estimate Date" type="date" value={order.date_orderdate} onChange={this.onChangeOrder('date_orderdate')} onBlur={onUpdateOrder(order.order_id, 'date_orderdate', order.date_orderdate)} disabled={locked} />
        <LabelledTextArea className="small-12 columns" label="Introduction" value={intro} onChange={this.onChangeOrder('introduction')} onBlur={onUpdateOrder(order.order_id, 'introduction', order.introduction)} disabled={locked} style={{ minWidth: '100%' }} />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          label="Billing Address"
          address={billing_address}
          contact={billing_contact}
          onChangeContact={onUpdateBillingContact(order.order_id, order.billing_contact_id, order.billing_address_id)}
          onChangeAddress={onUpdateOrder(order.order_id, 'billing_address_id', order.billing_address_id)}
          addresses={billingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'billing_address_id', order.billing_address_id, 'BILLING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'billing_contact_id', order.billing_contact_id)}
        />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          label="Main Shipping Address"
          address={shipping_address}
          contact={shipping_contact}
          onChangeContact={onUpdateShippingContact(order.order_id, order.shipping_contact_id, order.shipping_address_id)}
          onChangeAddress={onUpdateShippingAddress(order.order_id, order.shipping_address_id)}
          addresses={shippingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'shipping_address_id', order.shipping_address_id, 'SHIPPING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'shipping_contact_id', order.shipping_contact_id)}
        />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Terms" value={order.terms_id} options={terms} onChange={onUpdateOrder(order.order_id, 'terms_id', order.terms_id)} disabled={locked} />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Currency" value={order.currency_id} options={currencies} onChange={onUpdateOrder(order.order_id, 'currency_id', order.currency_id)} disabled={true} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="In Hands Date" type="date" value={order.date_inhandsdate} onChange={this.onChangeOrder('date_inhandsdate')} onBlur={onUpdateOrder(order.order_id, 'date_inhandsdate', order.date_inhandsdate)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Default Margin" type="text" disabled={true} value={order.default_margin} onBlur={onUpdateOrder(order.order_id, 'default_margin', order.default_margin)} />
        {this.renderTaxInput()}
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Customer PO" type="text" value={order.customer_po} onBlur={onUpdateOrder(order.order_id, 'customer_po', order.customer_po)} onChange={this.onChangeOrder('customer_po')} disabled={locked} />
      </div>
    );
  }

  renderSalesOrderInfo() {
    const { order } = this.state;
    const {
      project,
      billing_address,
      billing_contact,
      shipping_address,
      shipping_contact,
      shippingAddresses,
      billingAddresses,
      contacts,
      statuses,
      currencies,
      terms,
      onUpdateOrder,
      onUpdateBillingContact,
      onUpdateShippingContact,
      onUpdateShippingAddress,
      onAddAddress,
      onAddContact
    } = this.props;
    const locked = 1 == order.locked;
    const intro = order && order.introduction ? (order.introduction).replace(/\<br \/?>/g, "") : '';

    return (
      <div>
        <div className="small-12 large-3 large-push-9 columns">
          <a className="hollow button trigger-button" style={{ float: 'right' }} onClick={this.handleToggleCollapse}>- Collapse Info</a>
        </div>
        <LabelledSelect className="small-12 medium-6 large-3 large-pull-3 columns" label="Status" labelStyle={{ lineHeight: 1.55 }} value={order.status_id} options={statuses} onChange={onUpdateOrder(order.order_id, 'status_id', order.status_id)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns"
          label="Sales Order Date" type="date" value={order.date_orderdate}
          onChange={this.onChangeOrder('date_orderdate')}
          onBlur={
            onUpdateOrder(order.order_id, 'date_orderdate', order.date_orderdate)}
          disabled={locked} />
        <LabelledTextArea className="small-12 columns" label="Introduction" value={intro} onChange={this.onChangeOrder('introduction')} onBlur={onUpdateOrder(order.order_id, 'introduction', order.introduction)} disabled={locked} style={{ minWidth: '100%' }} />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          label="Billing Address"
          address={billing_address}
          contact={billing_contact}
          onChangeContact={onUpdateBillingContact(order.order_id, order.billing_contact_id, order.billing_address_id)}
          onChangeAddress={onUpdateOrder(order.order_id, 'billing_address_id', order.billing_address_id)}
          addresses={billingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'billing_address_id', order.billing_address_id, 'BILLING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'billing_contact_id', order.billing_contact_id)}
        />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          label="Main Shipping Address"
          address={shipping_address}
          contact={shipping_contact}
          onChangeContact={onUpdateShippingContact(order.order_id, order.shipping_contact_id, order.shipping_address_id)}
          onChangeAddress={onUpdateShippingAddress(order.order_id, order.shipping_address_id)}
          addresses={shippingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'shipping_address_id', order.shipping_address_id, 'SHIPPING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'shipping_contact_id', order.shipping_contact_id)}
        />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Terms" value={order.terms_id} options={terms} onChange={onUpdateOrder(order.order_id, 'terms_id', order.terms_id)} disabled={locked} />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Currency" value={order.currency_id} options={currencies} onChange={onUpdateOrder(order.order_id, 'currency_id', order.currency_id)} disabled={true} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="In Hands Date" type="date" value={order.date_inhandsdate} onChange={this.onChangeOrder('date_inhandsdate')} onBlur={onUpdateOrder(order.order_id, 'date_inhandsdate', order.date_inhandsdate)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Default Margin" type="text" disabled={true} value={order.default_margin} onBlur={onUpdateOrder(order.order_id, 'default_margin', order.default_margin)} />
        {this.renderTaxInput()}
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Customer PO" type="text" value={order.customer_po} onBlur={onUpdateOrder(order.order_id, 'customer_po', order.customer_po)} onChange={this.onChangeOrder('customer_po')} disabled={locked} />
        <LabelledCheckbox className="small-12 medium-6 large-3 columns" label="This is a sample order" value={order.sample_order} onChange={onUpdateOrder(order.order_id, 'sample_order', order.sample_order)} />
      </div>
    );
  }

  renderInvoiceInfo() {
    const { order } = this.state;
    const {
      project,
      billing_address,
      billing_contact,
      shipping_address,
      shipping_contact,
      shippingAddresses,
      billingAddresses,
      contacts,
      statuses,
      currencies,
      terms,
      credit_card_payable,
      stripe_cc_payable,
      stripe_ach_payable,
      onUpdateOrder,
      onUpdateBillingContact,
      onUpdateShippingContact,
      onUpdateShippingAddress,
      onAddAddress,
      onAddContact
    } = this.props;
    const locked = 1 == order.locked;
    const intro = order && order.introduction ? (order.introduction).replace(/\<br \/?>/g, "") : '';
    return (
      <div>
        <div className="small-12 large-3 large-push-9 columns">
          <a className="hollow button trigger-button" style={{ float: 'right' }} onClick={this.handleToggleCollapse}>- Collapse Info</a>
        </div>
        <LabelledSelect className="small-12 medium-6 large-3 large-pull-3 columns" label="Status" labelStyle={{ lineHeight: 1.55 }} value={order.status_id} options={statuses} onChange={onUpdateOrder(order.order_id, 'status_id', order.status_id)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 large-pull-3 columns" label="Invoice Date" type="date" value={order.date_orderdate} onChange={this.onChangeOrder('date_orderdate')} onBlur={onUpdateOrder(order.order_id, 'date_orderdate', order.date_orderdate)} disabled={locked} />
        <LabelledTextArea className="small-12 columns" label="Introduction" value={intro} onChange={this.onChangeOrder('introduction')} onBlur={onUpdateOrder(order.order_id, 'introduction', order.introduction)} disabled={locked} style={{ minWidth: '100%' }} />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          style={{ minHeight: '240px' }}
          label="Billing Address"
          address={billing_address}
          contact={billing_contact}
          onChangeContact={onUpdateBillingContact(order.order_id, order.billing_contact_id, order.billing_address_id)}
          onChangeAddress={onUpdateOrder(order.order_id, 'billing_address_id', order.billing_address_id)}
          addresses={billingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'billing_address_id', order.billing_address_id, 'BILLING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'billing_contact_id', order.billing_contact_id)}
        />
        <LabelledAddress
          className="small-12 medium-6 large-3 columns"
          style={{ minHeight: '240px' }}
          label="Main Shipping Address"
          address={shipping_address}
          contact={shipping_contact}
          onChangeContact={onUpdateShippingContact(order.order_id, order.shipping_contact_id, order.shipping_address_id)}
          onChangeAddress={onUpdateShippingAddress(order.order_id, order.shipping_address_id)}
          addresses={shippingAddresses}
          contacts={contacts}
          disabled={locked}
          canCreate={this.hasCapability('MODIFY-DOWNSTREAM-ACCOUNT')}
          onAddAddress={onAddAddress(order.order_id, 'shipping_address_id', order.shipping_address_id, 'SHIPPING')}
          onAddContact={onAddContact(order.order_id, project.account_id, 'shipping_contact_id', order.shipping_contact_id)}
        />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Terms" value={order.terms_id} options={terms} onChange={onUpdateOrder(order.order_id, 'terms_id', order.terms_id)} disabled={locked} />
        <LabelledSelect className="small-12 medium-6 large-3 columns" label="Currency" value={order.currency_id} options={currencies} onChange={onUpdateOrder(order.order_id, 'currency_id', order.currency_id)} disabled={true} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="In Hands Date" type="date" value={order.date_inhandsdate} onChange={this.onChangeOrder('date_inhandsdate')} onBlur={onUpdateOrder(order.order_id, 'date_inhandsdate', order.date_inhandsdate)} disabled={locked} />
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Default Margin" type="text" disabled={true} value={order.default_margin} onBlur={onUpdateOrder(order.order_id, 'default_margin', order.default_margin)} />
        {this.renderTaxInput()}
        <LabelledInput className="small-12 medium-6 large-3 columns" label="Customer PO" type="text" value={order.customer_po} onBlur={onUpdateOrder(order.order_id, 'customer_po', order.customer_po)} onChange={this.onChangeOrder('customer_po')} disabled={locked} />
        <LabelledRadio className="small-12 medium-6 large-3 columns" label="Invoice View" value={order.view_type} options={INVOICE_VIEW_TYPES} onChange={onUpdateOrder(order.order_id, 'view_type', order.view_type)} disabled={locked} />
        {(credit_card_payable === 'CARDCONNECT' || (credit_card_payable === 'STRIPE' && stripe_cc_payable)) && <LabelledCheckbox className="small-12 medium-6 large-3 columns" label="Allow credit card" value={order.allow_cc_payment} onChange={onUpdateOrder(order.order_id, 'allow_cc_payment', order.allow_cc_payment)} disabled={locked} />}
        { order.order_type === 'INVOICE' && credit_card_payable === 'STRIPE' && stripe_ach_payable && <LabelledCheckbox className="small-12 medium-6 large-3 columns" label="Allow ACH" value={order.allow_ach_payment} onChange={onUpdateOrder(order.order_id, 'allow_ach_payment', order.allow_ach_payment)} disabled={locked} />}
      </div>
    );
  }

  hasCapability(capability) {
    return this.props.identity.capabilities.includes(capability);
  }

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}}>
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  exportPartialsCSV() {
    const obj = {
      'report_type': 'partials',
      'export_all': true,
      'form_number': this.state.order.form_number,
      'order_ids': JSON.stringify(this.state.selected_partials)
    };

    if (this.state.selected_partials.length < 50) {
      openWindowWithPost("/export_report.php", obj);
    } else {
      oauth('POST', `export-report-task`, obj).then(({ json }) => {
        this.setState((prev) => {return {
          ...prev,
          exportTaskIds: [...prev.exportTaskIds, json.export_report_task.export_report_task_id],
        };});
      });
    }
  }

  exportPartialsPDF(selected) {
    if (!selected.length) return;
    const obj = {
      'print_checkouts': 1,
      'actionName': 'partials-pdf',
      'report_type': 'partials',
      'shop_id': this.props.order.shop_id,
      'order_ids': JSON.stringify(selected)
    };

    if (selected.length < 50) {
      const formData = new FormData();
      for (let key in obj) {
        if (key === 'order_ids') {
          selected.forEach(v => {
            formData.append('order_ids[]', v);
          });
        } else {
          formData.append(key, obj[key]);
        }
      }
      this.setState({ exportingPdf: true, });
      oauth('POST', 'export', {}, formData, {headers: {'Accept': 'application/pdf'}}, (response) => {
        if (!response.ok) {
          return 'Unable to generate PDF';
        }
        return response.blob();
      }).then((file) => {
        this.setState({ exportPdfFile: file });
      }).catch(() => {
        this.setState({ exportPdfFile: 'Unable to generate PDF' });
      });
    } else {
      oauth('POST', 'export-report-task', obj).then(({ json }) => {
        this.setState((prev) => {
          const exportReportTask = json?.export_report_task;
          const isComplete = Boolean(exportReportTask?.result) ||
            Boolean(exportReportTask?.date_finished) ||
            Boolean(exportReportTask?.error) ||
            Boolean(exportReportTask?.date_failed);
          return {
            ...prev,
            exportingPdf: !isComplete,
            exportReportTask: exportReportTask,
          };
        });
      });
    }
  }

  handleLoadPartials() {
    const { order, onLoadPartials } = this.props;
    const { partials_loaded } = this.state;
    if (!partials_loaded) {
      onLoadPartials(order.job_id).then(() => {
        this.setState({ partials_loaded: true});
      });
    }
  }

  handleClickPartial(order_id) {
    const { selected_partials } = this.state;

    if (selected_partials.includes(order_id)) {
        this.setState({ selected_partials: selected_partials.filter(id => id !== order_id) });
    } else {
        this.setState({ selected_partials: selected_partials.concat(order_id) });
    }
  }

  handleSelectAllPartials(checked) {
    if(checked) {
      this.setState({ selected_partials: [] });
    }else{
      this.setState({ selected_partials: this.props.partials.map(o => o.order_id) });
    }
  }

  handleRemovePartials() {
    const { order, selected_partials } = this.state;
    const { onCreateRemovePartials } = this.props;

    this.setState({ in_aggregating: true }, function() {
      onCreateRemovePartials(selected_partials, order.order_id);
      this.checkShopAggregateStatus();
    });
  }

  render() {
    const { order, selected_partials, partials_loaded } = this.state;

    if (!order.loaded) {
      return this.renderLoading();
    }

    const {
      location,
      services,
      project,
      deposit_invoice,
      onUpdateOrder,
      onAddDepositInvoice,
      onDeleteDepositInvoice,
      onUpdateDepositInvoice,
      onUpdateItemSummaryView,
      onUpdateItemSummaryTab,
      onUpdateItemSummaryShowActive,
      hidden_filter_status,
      product_list_style,
      tab,
      collapsed,
      identity,
      partials,
      onCreatePartialPopup,
      onCreateErrorPopup,
      stripe_cc_payable,
      stripe_ach_payable,
      credit_card_payable
    } = this.props;

    const hasCapability = this.hasCapability;

    const has_lockable_status = ['Invoiced', 'Paid', 'Exported'].includes(order.status_name);
    const has_unlock = hasCapability(`UNLOCK-${order.order_type.replace(/ /g, '-')}`);

    const handleUnlockOrder = e => {
      e.preventDefault();
      if (!has_unlock) {
        return;
      }
      onUpdateOrder(order.order_id, 'locked', order.locked)(1 == order.locked ? 0 : 1);
    };

    return (
      <div className="main-content project-order">
        {
          config.sanmar_show_modal && find(order.items, {supplier_id: '9da87da3-7ce5-490d-9e0d-d59962ead31f'})
          ? <SanmarNotificationBar/> : null
        }
        {collapsed ? this.renderBasicInfo() :
         'PRESENTATION' === order.order_type ? this.renderPresentationInfo() :
         'ESTIMATE' === order.order_type ? this.renderEstimateInfo() :
         'SALES ORDER' === order.order_type ? this.renderSalesOrderInfo() :
         this.renderInvoiceInfo()}
        <div className="small-12 columns">
          <ul className="switches">
            {'products' === tab ? <li>
              <div className="button-group compact-detailed-switch">
                {'PRESENTATION' !== order.order_type ?
                  <a onClick={e => { e.preventDefault(); onUpdateItemSummaryView(order.order_id, 'compact'); }} className={'button' + ('compact' === product_list_style ? ' active' : '')}>Compact</a>
                : null }
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryView(order.order_id, 'detailed'); }} className={'button' + ('detailed' === product_list_style ? ' active' : '')}>Detailed</a>
                {'PRESENTATION' === order.order_type ?
                  <a onClick={e => { e.preventDefault(); onUpdateItemSummaryView(order.order_id, 'grid'); }} className={'button' + ('grid' === product_list_style ? ' active' : '')}>
                    Grid
                  </a> : null}
              </div>
            </li> : null}
            {'info' !== tab && 'SALES ORDER' !== order.order_type && 'INVOICE' !== order.order_type ? <li>
              <div className="button-group compact-detailed-switch">
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryShowActive(order.order_id, 'all'); }} className={'button' + ('all' === hidden_filter_status ? ' active' : '')}>Show All</a>
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryShowActive(order.order_id, 'active'); }} className={'button' + ('active' === hidden_filter_status ? ' active' : '')}>Show Active</a>
              </div>
            </li> : null}
            {1 == order.locked || has_lockable_status ? <li>
              <div className="button-group compact-detailed-switch">
                <a onClick={handleUnlockOrder} className={'button' + (1 == order.locked ? ' active' : '')}>Locked</a>
                {has_unlock ? <a onClick={handleUnlockOrder} className={'button' + (0 == order.locked ? ' active' : '')}>Unlocked</a> : null}
              </div>
            </li> : null}
          </ul>
          <ul className="tabs" id="order-tabs">
            <li className={'tabs-title' + ('products' === tab ? ' is-active' : '')}>
              <a onClick={e => { e.preventDefault(); onUpdateItemSummaryTab(order.order_id, 'products'); }} aria-selected={'products' === tab}>Products</a>
            </li>
            {'ESTIMATE' === order.order_type || 'SALES ORDER' === order.order_type || 'PRESENTATION' === order.order_type || 'COLLECTION' === order.order_type ?
              <li className={'tabs-title' + ('artwork' === tab ? ' is-active' : '')}>
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryTab(order.order_id, 'artwork'); }} aria-selected={'artwork' === tab}>Artwork</a>
              </li>
            : null }
            {'PRESENTATION' === order.order_type ?
              <li className={'tabs-title' + ('comment' === tab ? ' is-active' : '')} style={{'display': 'none'}}>
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryTab(order.order_id, 'comment'); }} aria-selected={'comment' === tab}>Comment</a>
              </li>
            : null}
            {'SALES ORDER' === order.order_type && order.is_aggregate == 1 ?
              <li className={'tabs-title' + ('partial' === tab ? ' is-active' : '')}>
                <a onClick={e => { e.preventDefault(); onUpdateItemSummaryTab(order.order_id, 'partial'); this.handleLoadPartials(); }} aria-selected={'partial' === tab}>Checkouts</a>
              </li>
            : null}
            {tab === 'partial' && selected_partials.length && order.locked == 0 ?
              <Button size='small' variant='error' style={{ margin: '15px 0px 0px 0px', float: 'right' }} onClick={e => { e.preventDefault(); this.state.in_aggregating ? onCreateErrorPopup() : this.handleRemovePartials(); }}>Remove Checkouts</Button>
            : null}
            {tab === 'partial' && selected_partials.length ?
              <Dropdown
                style={{ margin: '15px 10px 0px 0px', float: 'right' }}
                buttonProps={{ disabled: !selected_partials.length }}
                buttonVariant='primary'
                size='small'
                text="Export Checkouts"
                items={[
                  {
                    onClick: e => {e.preventDefault(); this.exportPartialsCSV();},
                    content: "Export CSV",
                    active: selected_partials.length > 0,
                    show: selected_partials.length > 0,
                  },
                  {
                    onClick: e => {e.preventDefault(); this.exportPartialsPDF(selected_partials);},
                    content: "Export PDF",
                    active: selected_partials.length > 0,
                    show: selected_partials.length > 0,
                  },
                ]} />
            : null}
            {tab === 'partial' && selected_partials.length ?
              <Button size='small' style={{ margin: '15px 10px 0px 0px', float: 'right' }} onClick={e => { e.preventDefault(); onCreatePartialPopup(selected_partials); }}>View Checkouts</Button>
            : null}
          </ul>
          <div className="tabs-content" data-tabs-content="order-tabs">
            <div className="tabs-panel is-active">
               {'partial' === tab ?
                <PartialList
                  partials_loaded={partials_loaded}
                  partials={partials}
                  parent_order_id={order.order_id}
                  selected={selected_partials}
                  handleClickPartial={this.handleClickPartial}
                  handleSelectAllPartials={this.handleSelectAllPartials}
                  handleLoadPartials={this.handleLoadPartials}
                />
              :
                <ItemList
                  key={order.order_id}
                  location={location}
                  order_id={order.order_id}
                  order_type={order.order_type}
                  order_currency_id={order.currency_id}
                  items={order.items}
                  services={services}
                  display={'artwork' === tab || 'comment' === tab ? tab : product_list_style}
                  hidden_filter_status={hidden_filter_status}
                  locked={1 == order.locked}
                  is_aggregate_so={1 == order.is_aggregate && order.order_type === 'SALES ORDER'}
                />
              }
            </div>
          </div>
          {
            order.items.length > 0 &&
            <div className="row collapse" style={{ maxWidth: 'initial' }}>
              {'INVOICE' === order.order_type ?
                <ProjectMarginSummary project={project} order_id={order.parent_order_id} /> :
                'SALES ORDER' === order.order_type || 'ESTIMATE' === order.order_type ?
                  <OrderMarginSummary order={order} /> :
                  null}
              {'INVOICE' === order.order_type || 'SALES ORDER' === order.order_type || 'ESTIMATE' === order.order_type ?
                <div className="large-4 columns">&nbsp;</div> :
                <div className="large-8 columns">&nbsp;</div>}
              {'PRESENTATION' !== order.order_type && 'products' === tab ? <OrderSummary stripe_cc_payable={stripe_cc_payable} stripe_ach_payable={stripe_ach_payable} credit_card_payable={credit_card_payable} order={order} onUpdateOrder={onUpdateOrder} onAddDepositInvoice={onAddDepositInvoice} onUpdateDepositInvoice={onUpdateDepositInvoice} onDeleteDepositInvoice={onDeleteDepositInvoice} deposit_invoice={deposit_invoice} identity={identity} /> : null}
            </div>
          }
        </div>
        {this.state.exportingPdf
        ? <ExportViewerPopup
            onClose={() => this.setState({ exportingPdf: false, exportPdfFile: undefined, exportReportTask: null, })}
            exportTask={this.state.exportReportTask}
            file={this.state.exportPdfFile}
          />
        : (
          this.state.exportTaskIds.length > 0
            ? <ExportViewerPortal>
                <ExportViewer exportTaskIds={this.state.exportTaskIds} />
              </ExportViewerPortal>
            : null
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    onAddAddress: (order_id, field, previous_value, address_type) => () => {
      dispatch(createAddAddressPopup(order_id, field, previous_value, ownProps.project.account.account_id, 'CLIENT', null, null, address_type));
    },
    onAddContact: (order_id, account_id, field, previous_value) => () => {
      dispatch(createAddContactPopup(order_id, account_id, field, previous_value, ownProps.project.account.account_id, 'CLIENT'));
    },
    onUpdateBillingContact: (order_id, previous_contact_id, previous_address_id, contact_id) => contact_id => {
      dispatch(updateOrderContact('billing')(order_id, previous_contact_id, previous_address_id, contact_id));
    },
    onUpdateShippingContact: (order_id, previous_contact_id, previous_address_id, contact_id) => contact_id => {
      dispatch(updateOrderContact('shipping')(order_id, previous_contact_id, previous_address_id, contact_id));
    },
    onUpdateShippingAddress: (order_id, previous_address_id) => address_id => {
      dispatch(updateOrderAddress('shipping')(order_id, previous_address_id, address_id));
    },
    onUpdateOrder: (order_id, field, previous_value) => value => {
      if(field === 'status_id' && value === '') {
        return;
      }

      dispatch(updateOrder(order_id, field, previous_value, value));
    },
    onAddDepositInvoice: (job_id, order_id) => {
      dispatch(createAddDepositInvoice(job_id, order_id));
    },
    onDeleteDepositInvoice: (deposit_id) => {
      dispatch(createDeleteDepositInvoice(deposit_id));
    },
    onUpdateDepositInvoice: (deposit_id, field, previous_value) => value => {
      dispatch(createUpdateDepositInvoice(deposit_id, field, previous_value, value));
    },
    onUpdateItemSummaryView: (order_id, view_type) => {
      dispatch(createUpdateDisplayItemSummaryView(order_id, view_type));
    },
    onUpdateItemSummaryTab: (order_id, tab) => {
      dispatch(createUpdateDisplayItemSummaryTab(order_id, tab));
    },
    onUpdateItemSummaryShowActive: (order_id, filter) => {
      dispatch(createUpdateDisplayItemSummaryShowActive(order_id, filter));
    },
    onUpdateCollapsed: (order_id, collapsed) => {
      dispatch(createUpdateCollapseOrderInfo(order_id, collapsed));
    },
    onCreatePartialPopup: order_ids => dispatch(createPartialPopup(order_ids)),
    onCreateRemovePartials: (order_ids, order_id) => {
      dispatch(createValidateConfirmationPopup(() => {
        return dispatch(createRemovePartials(order_ids, order_id));
      }, [], 'You Sure?', 'Selected checkout(s) will be moved back to the Shop Checkout areas.  You may delete them from there if they are not longer required.', true));
    },
    onCreateErrorPopup: () => dispatch(createErrorPopup('There is a removing checkouts process running for this shop. Please wait for it to finish first.')),
    onCreateNoticePopup: () => dispatch(createNoticePopup('Removing checkouts is in progress. It may take a while so please grab a coffee.')),
    onCreateClosePopup: () => dispatch(closePopup()),
    onLoadPartials: job_id => dispatch(createLoadPartialList(job_id)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.order.form_number, order_type: ownProps.order.order_type });
  const project = ownProps.project;
  const deposit_invoice = Object.values(state.entities.deposit_invoices).filter(d => d.order_id === order.order_id)[0];
  const order_display = Object.assign({}, {
    item_summary_view: 'detailed',
    item_summary_show_active: 'all',
    item_summary_tab: 'products',
    collapsed: false
  }, state.display.orders[order.order_id]);
  const product_list_style = order_display.item_summary_view;
  const tab = order_display.item_summary_tab;
  const collapsed = order_display.collapsed;
  const partials = Object.values(state.entities.orders).filter(o => o.order_type === 'PARTIAL');
  const credit_card_payable = state.entities.company_data[state.identity.company_id].credit_card_payable;
  const stripe_cc_payable = state.entities.company_data[state.identity.company_id].stripe_cc_payable;
  const stripe_ach_payable = state.entities.company_data[state.identity.company_id].stripe_ach_payable;
  return {
    identity: state.identity ?? false,
    order: order,
    collapsed,
    product_list_style,
    hidden_filter_status: order_display.item_summary_show_active,
    tab,
    billing_address: getAddress(state, { address_id: order.billing_address_id }),
    billing_contact: getContact(state, { contact_id: order.billing_contact_id }),
    shipping_address: getAddress(state, { address_id: order.shipping_address_id }),
    shipping_contact: getContact(state, { contact_id: order.shipping_contact_id }),
    shippingAddresses: getShippingAddressOptions(state, { parent_id: project.account_id }),
    billingAddresses: getBillingAddressOptions(state, { parent_id: project.account_id }),
    contacts: getContactOptions(state, { parent_id: project.account_id }),
    statuses: getStatusOptions(state, order),
    services: getServiceOptions(state),
    currencies: getCurrencyOptions(state),
    terms: getTermsOptions(state),
    taxes: getTaxOptions(state),
    project: project,
    /* mapStateToProps should not allocate new function,
     * it will cause re-render on every action.
     * move hasCapability inside Component for now,
     * should switch to the one in util.js
     * */
    deposit_invoice: deposit_invoice,
    partials: partials,
    credit_card_payable,
    stripe_cc_payable,
    stripe_ach_payable,
    company_data: getCompanyData(state),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOrder);
