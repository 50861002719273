import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import { FILTER_TYPES, ReportFilterConfigBase } from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
    {
        label: "Project",
        fields: "job_name_or_number",
        type: FILTER_TYPES.ClientPageProjectQuery,
        fetchReportOnEnterPress: true,
        showLabel: false,
    },
    {
        label: "Date",
        type: FILTER_TYPES.DateRange,
        fields: dateRangePickerFields,
        defaultValue: ["this_year", "", ""],
        placeholder: "Any date",
        showLabel: false,
    },
    {
        label: "Stage",
        type: FILTER_TYPES.FormType,
        fields: "order_type",
        placeholder: "Any stage",
        showLabel: false,
    },
    /*
    {
        label: "Order Status",
        type: FILTER_TYPES.OrderStatus,
        fields: "status_id",
        dependsOn: {
            filterFields: ["order_type"],
        },
        placeholder: "Order Status",
        showLabel: false,
    },
   */
    {
        label: "Rep",
        type: FILTER_TYPES.User,
        fields: ["sales_rep_id", "sales_rep_teams", "include_inactive_order_reps"],
        placeholder: "Any rep",
        showLabel: false,
    },
    {
        label: "Show Deleted",
        fields: "show_deleted",
        type: FILTER_TYPES.Checkbox,
        placeholder: "Show Deleted",
        showLabel: false,
    },
];
