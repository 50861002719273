import {
  Popup,
  Text,
  XIcon,
  Tabs,
  colors,
  EmptyStateArrow,
} from "@commonsku/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { POPUP_MAX_HEIGHT, POPUP_MAX_WIDTH } from "../SelectThemePopup/helpers";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createSpinnerPopup } from "../../../../actions/popup";
import {
  PublicViewTemplateOrderType,
  TemplatesData,
} from "../../../../containers/ShopTemplatesApp";
import TemplateTile from "../../TemplateTile/TemplateTile";
import { BASE_ZINDEX } from "../../../../popup-factory";
import { createShop } from "../../../../actions/shop";
import "./ShopInitializationTemplatePopup.css";
import { useIdentity } from "../../../../hooks";
import styled from "styled-components";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

const StyledTemplateDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
`;

const StyledTemplateText = styled.p`
  color: ${colors.neutrals.bodyText};
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
`;

interface ShopInitializationTemplatePopupProps {
  shop_name: string;
  client_id: string;
  aggregate: number;
  redirect: boolean;
  templateOrderTypes: PublicViewTemplateOrderType[];
}
const ShopInitializationTemplatePopup = ({
  shop_name,
  client_id,
  aggregate,
  redirect,
  templateOrderTypes,
}: ShopInitializationTemplatePopupProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const ref = useRef(null);
  const onPopupClose = () => dispatch(closePopup());
  const { company_name } = useIdentity();
  const [tabPosition, setTabPosition] = useState<number>();

  const publicViewTemplates = useSelector(
    (state: any) => state.entities.public_view_templates
  );
  const templates = useMemo(
    () =>
      Array.isArray(publicViewTemplates)
        ? publicViewTemplates
        : Object.values(publicViewTemplates),
    [publicViewTemplates]
  );

  const companyTemplates = templates.filter(
    (template) => template.template_type === "TEMPLATE" && template.company_id
  );

  const shopDefaultTemplate = useMemo(() => {
    const shopDefaultTemplateIds = templateOrderTypes
      .filter((v) => v.order_type === "SHOP" && +v.is_default === 1)
      .map((v) => v.public_view_template_id);
    return templates.find(
      (t) =>
        t.template_type !== "LAYOUT" &&
        shopDefaultTemplateIds.includes(t.public_view_template_id)
    );
  }, [templates, templateOrderTypes]);
  const shopDefaultTemplateId = shopDefaultTemplate?.public_view_template_id;

  const onCreateShop = async (
    public_view_template_id: string,
    isCustomize = false
  ): Promise<void> => {
    dispatch(createSpinnerPopup("Creating shop..."));
    try {
      await dispatch(
        createShop(
          shop_name,
          client_id,
          aggregate,
          redirect,
          public_view_template_id,
          isCustomize
        )
      );
    } catch (error) {
      onPopupClose();
      throw error;
    }
  };

  useEffect(() => {
    const tabElems = document
      .querySelector(
        ".popup.select-template-popup .popup-content.select-template-popup-content .template-tabs-wrapper > div > ul"
      )
      .querySelectorAll("li");

    if ((tabElems?.length || 0) > 0) {
      const companyTabPos = tabElems[0].getBoundingClientRect();
      const commonskuTabPos = tabElems[1].getBoundingClientRect();

      const tabCenter =
        companyTabPos.width + commonskuTabPos.width / 2 + 24 + 15;
      setTabPosition(tabCenter - 95);
    }
  }, []);

  const templateTabs = (
    <Tabs
      padded={false}
      variant={"secondary"}
      tabs={[
        {
          label: `${company_name} Templates`,
          content:
            companyTemplates.length > 0 ? (
              <TemplateTile
                templates={templates.filter(
                  (template: TemplatesData) =>
                    template.template_type !== "LAYOUT" && template.company_id
                )}
                shopDefaultTemplateId={shopDefaultTemplateId}
                isPopup
                tab="company"
                onCreateShop={onCreateShop}
              />
            ) : (
              <StyledTemplateDiv>
                <EmptyStateArrow
                  direction="up"
                  size="default"
                  style={{
                    height: "300px",
                    position: "absolute",
                    width: "190px",
                    display: "block",
                    left: tabPosition,
                    top: 110,
                  }}
                />
                <div style={{ marginTop: 250, textAlign: "center" }}>
                  <StyledTemplateText>
                    Use one a commonsku template to get started!
                  </StyledTemplateText>
                  <StyledTemplateText>
                    Your saved templates will show here once you build a Shop in
                    the Design Studio and ‘save as a new template’.
                  </StyledTemplateText>
                  {/* <a>Learn More About Templating</a> */}
                </div>
              </StyledTemplateDiv>
            ),
        },
        {
          label: "commonsku Templates",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) =>
                  template.status === "PUBLISHED" &&
                  template.template_type !== "LAYOUT" &&
                  !template.company_id
              )}
              shopDefaultTemplateId={shopDefaultTemplateId}
              isPopup
              tab="commonsku"
              onCreateShop={onCreateShop}
            />
          ),
        },
        {
          label: "Layouts",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) => template.template_type === "LAYOUT"
              )}
              isPopup
              tab="layouts"
              onCreateShop={onCreateShop}
            />
          ),
        },
      ]}
    />
  );
  return (
    <Popup
      ref={ref}
      closeOnEsc
      noHeader
      popupClassName="select-template-popup"
      contentClassName="select-template-popup-content"
      style={{
        maxHeight: POPUP_MAX_HEIGHT,
        maxWidth: POPUP_MAX_WIDTH,
        overflow: "hidden",
        padding: 24,
        background: "var(--color-neutrals-20)",
      }}
      overlayZIndex={BASE_ZINDEX}
      data-test-name="ShopInitializationTemplatePopup"
    >
      <Text
        as="p"
        style={{
          color: "var(--color-neutrals-90)",
          fontFamily: "var(--font-family-bold)",
          fontSize: 24,
          paddingLeft: 8,
        }}
      >
        Select a template or start fresh with a layout
      </Text>
      <div>
        <XIcon
          style={{
            position: "absolute",
            right: 5,
            cursor: "pointer",
            top: 5,
            zIndex: 9,
          }}
          onClick={onPopupClose}
        />
        <div
          className="template-tabs-wrapper"
          style={{
            overflow: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {templateTabs}
        </div>
      </div>
    </Popup>
  );
};

export default ShopInitializationTemplatePopup;
