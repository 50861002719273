import React from "react";
import SwitchStyleProps from "../components/SwitchStyleProps";
import {
  Dependency,
  FILTER_TYPES,
  FilterTypeValues,
  ReportFilterConfigBase,
  ReportFilterFields,
  ReportFilterValues,
  StyleProps,
} from "./types";
import {
  FILTER_COMPONENT_REGISTRY,
  TYPE_TO_STYLE_PROPS,
} from "../components/filter_registry";

export class ReportFilterConfig implements ReportFilterConfigBase {
  /**
   * Represents the configuration for a specific report filter.
   * This class encapsulates properties and methods necessary to define and manage
   * a filter's behavior and appearance in a report
   *
   * @param label - The label to display for the filter
   * @param reactComponent - The name of the react component to use for the filter
   * @param styleProps - The style properties to apply to the filter
   * @param fields - The fields to use for the filter, could be a string or an array of strings
   * @param id - the unique identifier for the filter for list rendering and ref
   * @param dependsOn - the fields that the filter depends on
   * @param placeholder - the placeholder text for the filter
   * @returns ReportFilterConfig
   */
  label: React.ReactNode;
  type: FilterTypeValues;
  reactComponent: string;
  styleProps: StyleProps;
  fields: ReportFilterFields;
  id: string;
  dependsOn?: Dependency;
  placeholder?: string;
  options?: (string | { label: string; value: string })[];
  containerStyle: React.CSSProperties = {};
  defaultValue?: ReportFilterValues;
  fetchReportOnEnterPress: boolean;
  dropdownOptionsFilter: {[key: string]: string};
  showLabel?: boolean;

  constructor({
    label,
    type,
    fields,
    dependsOn,
    placeholder,
    defaultValue,
    options,
    flexBasis,
    dropdownOptionsFilter,
    containerStyle = {},
    fetchReportOnEnterPress = false,
    showLabel = true,
  }: ReportFilterConfigBase) {
    this.label = label;
    this.reactComponent = this.getReactComponent(type);
    this.styleProps = this.getStyleProps(type, options);
    if (typeof flexBasis !== "undefined") {
      this.styleProps.flexBasis = flexBasis;
    }
    this.fields = fields;
    this.id = this.getIdByFields(fields);
    this.dependsOn = dependsOn;
    this.placeholder = placeholder;
    this.options = options;
    this.containerStyle = containerStyle;
    this.type = type;
    this.fetchReportOnEnterPress = fetchReportOnEnterPress;
    this.dropdownOptionsFilter = dropdownOptionsFilter;
    this.showLabel = showLabel;
    defaultValue && this.setDefaultValue(defaultValue);
  }

  private getIdByFields(fields: ReportFilterFields): string {
    return Array.isArray(fields) ? fields[0] : fields;
  }

  private getReactComponent(type: FilterTypeValues): string {
    const component = FILTER_COMPONENT_REGISTRY[type];
    if (!component) throw new Error(`Unknown filter type: ${type}`);

    return component;
  }

  private getStyleProps(
    type: FilterTypeValues,
    options?: (string | { label: string; value: string })[],
    placehoder?: string[],
  ): StyleProps {
    switch (type) {
      case FILTER_TYPES.Switch:
        return new SwitchStyleProps(options).getStyleProps();
      default:
        return TYPE_TO_STYLE_PROPS[type];
    }
  }

  private setDefaultValue(defaultValue: ReportFilterValues) {
    if (
      (Array.isArray(defaultValue) && !Array.isArray(this.fields)) ||
      (!Array.isArray(defaultValue) && Array.isArray(this.fields))
    ) {
      throw new Error(
        "Type mismatch: defaultValue must be an array if fields is an array and vice versa",
      );
    }
    this.defaultValue = defaultValue;
  }
}

export const FILTER_STYLE_CONSTANTS = {
  FILTER_ITEMS_GAP: 13,
  TWO_BTNS_AREA_WIDTH: 275,
  SINGLE_BTN_AREA_WIDTH: 121,
  MORE_FILTERS_BTN_WIDTH: 146,
  BTNS_GAP: 8,
} as const;
