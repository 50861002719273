import React, { useMemo, useState } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { Button, CheckmarkIcon, Csku, IconButton, Typography } from "@commonsku/styles";
import { restoreJob } from "../../../../redux/clientDetails";
import { getImageSrc } from "../../../../utils";
import { useDispatch } from "react-redux";
import { useTooltipAttributes } from "../../../../hooks";

const ITEM_IMAGE_STYLE = {
    maxWidth: 45,
    maxHeight: 45,
};

type TColumn = typeof BASE_COLUMN_TYPES.Project;
const ProjectCell = ({
    column,
    row,
    className,
    onClick,
    style = {},
}: CellProps<TColumn>) => {
    const { accessorKey, dataTransform } = column;
    const { job_id, job_name, job_number, items, job_active } = useMemo<ColumnDataMap<TColumn>>(
        () => ({ ...row.original[accessorKey] }),
        [accessorKey, dataTransform, row.original],
    );
    const jobLabel = `#${job_number}: ${job_name}`;
    const jobTooltipAttributes = useTooltipAttributes(jobLabel, REPORT_TOOLTIP_TARGET_ID);

    const dispatch = useDispatch();
    const [restored, setRestored] = useState(false);
    const href = `/project/${job_number}`;
    const itemsToShow = items.slice(0, 5);
    const showHref = job_active || restored;

    const handleRestore = () => {
        dispatch(restoreJob(job_id));
        setRestored(true);
    }

    return (
        <Csku className={className} onClick={onClick}>
            <div {...jobTooltipAttributes}>
                <Typography.Span
                    as={showHref ? "a" : "span"}
                    style={{
                        display: "block",
                        alignContent: "left",
                        color: showHref ? "var(--color-primary1-main)" : "unset",
                    }}
                    href={showHref ? href : undefined}
                    target="_blank"
                    rel="noreferrer"
                >
                    {jobLabel}
                </Typography.Span>
                {itemsToShow.map((item) => (
                    <img
                        key={item.item_id}
                        data-for={"report-table-tooltip"}
                        data-tip={item.item_name}
                        alt={item.item_name}
                        src={getImageSrc(item)}
                        style={ITEM_IMAGE_STYLE}
                    />
                ))}
                {!job_active && (!restored ? (
                    <div>
                        <Button
                            size="small"
                            onClick={handleRestore}
                        >
                            Restore
                        </Button>
                    </div>
                ) : (
                    <div>
                        <IconButton
                            size="small"
                            Icon={CheckmarkIcon}
                            iconPosition="left"
                            disabled
                        >
                            Restored
                        </IconButton>
                    </div>
                ))}
            </div>
        </Csku>
    );
};

export default ProjectCell;
